import React, {Component, Fragment} from 'react';
import GolferService from "../../../services/api/golfer";
import GolferRecord from "./GolferRecord";
import ConfirmationModalTypeB from "../../Modals/ConfirmationModalTypeB";

export default class Merge extends Component {


	constructor(props) {
		super(props);
    let defaultInputOne = '';
    if (this.props.fromAccountCommand) {
      let ghinNumber = this.props.fromAccountCommand.ghinNumber;
      defaultInputOne = ghinNumber.toString();
    }
		this.state = {
			ghinInputOne: defaultInputOne,
			ghinInputTwo: '',
			inputOneError: false,
			inputTwoError: false,
			golferOne: null,
			golferTwo: null,
			secondFieldDisabled: true,
			selectedRecordToKeep: 1,
			confirmationModalOpen: false,
			responseModalOpen: false,
			responseModalErrorOpen: false,
			mergeLoading: false
		};

		this.mergeGolfers = this.mergeGolfers.bind(this);
	}
  componentDidMount() {
    if (this.props.fromAccountCommand) {
      let e = new Event("default");
      this.searchGolferOne(e);
    }
  }

	setInitialState() {
		document.getElementById('golferone').value = null;
		document.getElementById('golfertwo').value = null;
		this.setState({
			ghinInputOne: '',
			ghinInputTwo: '',
			inputOneError: false,
			inputTwoError: false,
			golferOne: null,
			golferTwo: null,
			secondFieldDisabled: true,
			selectedRecordToKeep: 1,
			confirmationModalOpen: false,
			responseModalOpen: false,
			responseModalErrorOpen: false,
			mergeLoading: false
		})
	}

	searchGolferOne(e) {
    let ghin = "";
    if (e.type === "default") {
      // Gets here only if redirected from golfer Account "Merge" link.
      ghin = this.state.ghinInputOne;
    } else {
      ghin = (e.target.validity.valid) ? e.target.value : this.state.ghinInputOne;
    }
		this.setState({
			ghinInputOne: ghin
		});
		if (!ghin.length) {
			this.setState({
				golferOne: null
			});
			return;
		}
		if (this.state.golferTwo) {
			if (this.state.golferTwo.id.toString() === ghin) {
				return;
			}
		}
		GolferService.getGolferByGHIN(ghin)
			.then(response => {
				if (response && response.golfer) {
					this.setState({
						golferOne: response.golfer,
						secondFieldDisabled: false,
						inputOneError: false
					})
				} else {
					this.setState({
						golferOne: null,
						inputOneError: true
					})
				}
			})
			.catch(err => {
				console.error(err);
				this.setState({
					golferOne: null,
					inputOneError: true
				})
			});

	}

	searchGolferTwo(e) {
		const ghin = (e.target.validity.valid) ? e.target.value : this.state.ghinInputTwo;
		this.setState({
			ghinInputTwo: ghin
		});
		if (!ghin.length) {
			this.setState({
				golferTwo: null
			});
			return;
		};
		if (this.state.golferOne) {
			if (this.state.golferOne.id.toString() === ghin) {
				return;
			}
		}
		GolferService.getGolferByGHIN(ghin)
			.then(response => {
				if (response && response.golfer) {
					this.setState({
						golferTwo: response.golfer,
						inputTwoError: false
					})
				} else {
					this.setState({
						golferTwo: null,
						inputTwoError: true
					})
				}
			})
			.catch(err => {
				console.error(err);
				this.setState({
					golferTwo: null,
					inputTwoError: true
				})
			});
	}

	mergeGolfers() {
		this.setState({
			mergeLoading: true
		}, () => {
			GolferService.mergeGolfers({
				merger_id: this.state.selectedRecordToKeep === 1 ? this.state.golferOne.id : this.state.golferTwo.id,
				merged_id: this.state.selectedRecordToKeep === 1 ? this.state.golferTwo.id : this.state.golferOne.id
			})
				.then(response => {
					if (response.errors) {
						this.setState({
							confirmationModalOpen: false,
							responseModalOpen: false,
							mergeLoading: false,
							responseModalErrorOpen: response.errors
						})
					} else {
						this.setState({
							confirmationModalOpen: false,
							responseModalErrorOpen: false,
							mergeLoading: false,
							responseModalOpen: true
						})
					}

				})
				.catch(err => {
					console.error(err);
				})
		});

	}

	render() {
		return (
            <Fragment>

                <div className="col is-full">

                    <div className="row">

                        <div className="col is-1-of-3 jc-fs">
                            <div className="panel">
                                <div className="panel__head">Golfer 1</div>
                                <div className="panel__body">
                                    <div className="columns">
                                        <div className="row">
                                            <div className="col is-full">
                                                <input id={'golferone'} value={this.state.ghinInputOne} onChange={(e) => this.searchGolferOne(e)} type="text" pattern="[0-9]*" autoComplete="off" placeholder="GHIN Number" />

												{this.state.inputOneError && <span className={'validation__message is-error'}>GHIN # not found.</span>}
                                            </div>
                                        </div>
										<GolferRecord golfer={this.state.golferOne}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col is-1-of-3 jc-fs">
                            <div className="panel">
                                <div className="panel__head">Golfer 2</div>
                                <div className="panel__body">
                                    <div className="columns">
                                        <div className="row">
                                            <div className="col is-full">
                                                <input id={'golfertwo'} value={this.state.ghinInputTwo} onChange={(e) => this.searchGolferTwo(e)} type="text" pattern="[0-9]*" autoComplete="off" placeholder="GHIN Number" disabled={this.state.secondFieldDisabled} />
												{this.state.inputTwoError && <span className={'validation__message is-error'}>GHIN # not found.</span>}
                                            </div>
                                        </div>
										<GolferRecord golfer={this.state.golferTwo}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col is-1-of-3 jc-fs">
                            <div className="panel">
                                <div className="panel__head">Result</div>
                                <div className="panel__body">
                                    <div className="columns">
                                        <div className="row">
                                            <div className="col is-3-of-7 jc-c">
                                                Choose record to keep:
                                            </div>
                                            <div className="col is-4-of-7 jc-c">
												<div className="custom-radio__container">
													<input id={'golferOne'} type={'radio'} name={'golferOne'} onChange={(e) => this.setState({selectedRecordToKeep: 1})} checked={this.state.selectedRecordToKeep === 1}/>
													<label htmlFor="golferOne">Golfer 1</label>
													<input id={'golferTwo'} type={'radio'} name={'golferTwo'} onChange={(e) => this.setState({selectedRecordToKeep: 2})} checked={this.state.selectedRecordToKeep === 2}/>
													<label htmlFor="golferTwo">Golfer 2</label>
												</div>
                                            </div>
                                        </div>
										{this.state.selectedRecordToKeep === 1 && <GolferRecord golfer={{...this.state.golferOne, ...{email: this.state.golferOne && this.state.golferOne.email?  this.state.golferOne.email : this.state.golferTwo && this.state.golferTwo.email, gps_subscribed: this.state.golferOne && this.state.golferTwo? this.state.golferOne.gps_subscribed || this.state.golferTwo.gps_subscribed : undefined}}}/>}
										{this.state.selectedRecordToKeep === 2 && <GolferRecord golfer={{...this.state.golferTwo, ...{email: this.state.golferTwo && this.state.golferTwo.email?  this.state.golferTwo.email : this.state.golferOne && this.state.golferOne.email, gps_subscribed: this.state.golferOne && this.state.golferTwo? this.state.golferOne.gps_subscribed || this.state.golferTwo.gps_subscribed : undefined}}}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

										{ ((this.state.golferOne && this.state.selectedRecordToKeep === 1 && !this.state.golferOne.has_digital_profile && this.state.golferTwo && this.state.golferTwo.has_digital_profile) ||
											 (this.state.golferTwo && this.state.selectedRecordToKeep === 2 && !this.state.golferTwo.has_digital_profile && this.state.golferOne && this.state.golferOne.has_digital_profile)) &&
													<div className="col auto push-right">
															<span className={'validation__message is-error'}>
																	The chosen record does not have a digital profile. When merged the golfer will need to create a new one.
															</span>
															<br/>
													</div>
										}

                    <div className="row">
                        <div className="col auto push-right">
                            <button onClick={(e) => {
                            	e.preventDefault();
                            	if (this.state.golferOne && this.state.golferTwo && (this.props.superUser || !(this.state.golferOne.is_trial || this.state.golferTwo.is_trial))) {
                            		this.setState({
																	confirmationModalOpen: true
																})
															}
														}}
														className="btn fill green">
															Merge
														</button>
                        </div>
                    </div>

                </div>
				{this.state.confirmationModalOpen &&
					<ConfirmationModalTypeB
						openModal={this.state.confirmationModalOpen}
						onConfirmAction={() => this.mergeGolfers()}
						onCancelAction={() => {
							this.setState({
								confirmationModalOpen: false
							})
						}}
						closeModal={() => {
							this.setState({
								confirmationModalOpen: false
							})
						}}
						loading={this.state.mergeLoading}
						bodyQuestion={<Fragment>{this.state.golferOne.first_name} {this.state.golferOne.last_name} - {this.state.golferOne.id}
							<br/> {this.state.golferTwo.first_name} {this.state.golferTwo.last_name} - {this.state.golferTwo.id}</Fragment>}
						question={"Merge the following golfers?"}
						cancelLabel={"No"}
                        confirmLabel={"Yes"}
                        modalIcon={"help_outline"}
            mergeContext={true}
					/>
				}
				{this.state.responseModalOpen &&
					<ConfirmationModalTypeB
						openModal={this.state.responseModalOpen}
						onConfirmAction={() => this.setInitialState()}
						onCancelAction={() => {
							this.setState({
								responseModalOpen: false
							})
						}}
						closeModal={() => {
							this.setState({
								responseModalOpen: false
							})
						}}
						bodyQuestion={`The system has begun processing the merge between ${this.state.golferOne.first_name} ${this.state.golferOne.last_name} & ${this.state.golferTwo.first_name} ${this.state.golferTwo.last_name}. Please navigate to the golfer profiles to verify the merge.`}
						question={"Merge Submitted"}
                        confirmLabel={"Ok"}
                        modalIcon={"check_circle"}
					/>
				}

				{this.state.responseModalErrorOpen &&
					<ConfirmationModalTypeB
						openModal={this.state.responseModalErrorOpen}
						onConfirmAction={() => this.setState({
							responseModalErrorOpen: false
						})}
						onCancelAction={() => {
							this.setState({
								responseModalErrorOpen: false
							})
						}}
						closeModal={() => {
							this.setState({
								responseModalErrorOpen: false
							})
						}}
						bodyQuestion={<Fragment>{this.state.responseModalErrorOpen && this.state.responseModalErrorOpen.map(err => { return (<div className={'is-error'}>{err}</div>)})}</Fragment>}
						question={"Unable to merge golfers"}
                        confirmLabel={"Ok"}
                        modalIcon={"error_outline"}
					/>
				}
            </Fragment>
		);
	}
}

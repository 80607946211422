import React, {Component, Fragment} from 'react';
import {NavLink, Switch, Route, Redirect} from 'react-router-dom';
import Modal from 'react-modal';
import Header from '../../shared/Header';
import Navigation from '../../shared/Navigation';
import BreadCrumbsNav from '../../shared/BreadCrumbsNav';
import Footer from '../../shared/Footer';
import ClubList from './ClubList';
import ClubGroups from './ClubGroups';
import Account from './Account';
import AuditLog from './AuditLog';
import AssociationService from "../../../services/api/association";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {updateAssociation} from "./Account/Actions";
import {getPermission, getAllAccesses, checkAccess} from "../../../permissionManager";
import PageHead from "../../shared/PageHead";
import Newsletter from './Newsletter';
import GolferProducts from './GolferProducts';
import JoinAndRenew from './JoinAndRenew';
import JoinAndRenewServices from '../../../services/api/joinAndRenew';
import GhinConnect from './GhinConnect';
import NotFound from "../../NotFound/NotFound";
import Loader from "../../NotFound/Loader";

class Association extends Component {

	constructor(props) {

		super(props);

		this.state = {
          AddGolferModalIsOpen: false,
          AddClubModalIsOpen: false,
      AddAssocModalIsOpen: false,
			association: null,
			isLoading: true
		};

    this.updateJoinRenew = this.updateJoinRenew.bind(this);
        this.openGolferAddModal = this.openGolferAddModal.bind(this);
        this.openClubAddModal = this.openClubAddModal.bind(this);
        this.openAssocAddModal = this.openAssocAddModal.bind(this);

		this.closeModal = this.closeModal.bind(this);

	}

	openGolferAddModal() {
		this.setState({AddGolferModalIsOpen: true});
    }

    openClubAddModal() {
		this.setState({AddClubModalIsOpen: true});
    }

    openAssocAddModal() {
		this.setState({AddAssocModalIsOpen: true});
	}

	closeModal(data) {
		if(data && data.type === "golfer") {
			this.props.history.push(`/manage/association/${data.golfer.association_id}/club/${data.golfer.club_id}/golfer/${data.golfer.id}`);
		}
		if(data && data.type === "club"){
			this.props.history.push(`/manage/association/${data.club.club.golf_association_id}/club/${data.club.lub.id}/account`);
		}
		this.setState({
            AddGolferModalIsOpen: false,
            AddClubModalIsOpen: false,
            AddAssocModalIsOpen: false
        });
	}

	getAssociation() {
		AssociationService
			.getAssociation(this.props.match.params.id).then((association) => {
				this.setState({association, isLoading: false});
				this.props.updateAssociation({profile: {...association}})
			}).catch(() => {
				this.setState({isLoading: false});
			});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.getAssociation()
		}
	}

	componentDidMount() {
    JoinAndRenewServices
      .getSettings(this.props.match.params.id).then((res) => {
        const settings = res.settings
        this.setState({
          join_and_renew_enabled: settings.join_and_renew_enabled,
          email_communications_enabled: settings.email_communications_enabled,
        })
      }).finally(() => {
        this.getAssociation();
      })
        Modal.setAppElement('body');
  }

  updateJoinRenew(data) {
    const settings = data.settings
    this.setState({
      join_and_renew_enabled: settings.join_and_renew_enabled,
      email_communications_enabled: settings.email_communications_enabled,

    })
  }

	render() {
		if (this.state.isLoading) return <Loader />
		if (!this.state.association) {
			return <NotFound />
		}

		const user_access = getAllAccesses(this.props.rawAccess);
    const canSeeAssociationName = (checkAccess(user_access, this.state.association.id, null) || this.props.user_type === 'super-admin');
		return (
            <Fragment>
                <Header />
				<Navigation />
				<BreadCrumbsNav canSeeAssociationName={canSeeAssociationName} association={this.state.association}/>

				<main>
                        <div className="container">

							<PageHead title={this.props.associationName} association={this.state.association} />

                            <ul className="page__tabs">
                                <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/list`}>Club List</NavLink></li>
                                {process.env.REACT_APP_TYPE !== "hv" && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/group`}>Club Group</NavLink></li>}
                                {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/account`}>Account</NavLink></li>}
							{(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") &&
								(process.env.REACT_APP_TYPE !== "pilot" || [23, 98, 160, 20, 101, 81, 84, 18, 55, 10, 2, 73, 5, 19, 79, 1, 22, 57, 30, 65, 52, 104, 103, 53, 45].includes(this.state.association?.id)) &&
								<li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/ghin-connect`}>GHIN Connect</NavLink></li>}
              {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && this.state.association.newsletter_enabled && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/newsletter`}>Newsletter Setup</NavLink></li>}
              {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/golfer-products`}>Golfer Products</NavLink></li>}
              {process.env.REACT_APP_TYPE !== "hv" && this.state.join_and_renew_enabled && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/join-and-renew`}>Join & Renew</NavLink></li>}
								{process.env.REACT_APP_TYPE !== "hv" && this.props.user_type === "super-admin" && <li><NavLink className="page__tab" activeClassName="is-active" to={`${this.props.match.url}/audit-log`}>Audit Log</NavLink></li>}
                            </ul>

                            <section className="page__tab-panel">
                                <Switch>
                                    <Redirect exact from={`${this.props.match.url}`} to={`${this.props.match.url}/list`}/>
									<Route path={`${this.props.match.url}/list`} render={() =>
										<ClubList {...this.props}
												  canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
												  association={this.state.association}/>}/>
									{process.env.REACT_APP_TYPE !== "hv" && <Route path={`${this.props.match.url}/group`} render={() =>
										<ClubGroups {...this.props}
													canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
													props={this.props}/>}/>}
									{(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <Route path={`${this.props.match.url}/account`} render={() =>
										<Account associationId={this.props.match.params.id}
												 association={this.state.association}
												 update={()=>this.getAssociation()}
												 canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                    update_join_renew={this.updateJoinRenew}
                    join_and_renew_enabled={this.state.join_and_renew_enabled}
                         />}/>}
									{(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <Route path={`${this.props.match.url}/ghin-connect`} render={() =>
										<GhinConnect {...this.props}
												 association={this.state.association}
												 canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                         />}/>}
                  {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin")&&<Route path={`${this.props.match.url}/newsletter`} render={() =>
										<Newsletter associationId={this.props.match.params.id}
												 association={this.state.association}
												 canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                         />}/>}
                {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin") && <Route path={`${this.props.match.url}/join-and-renew`} render={() =>
                  <JoinAndRenew {...this.props}
                    associationId={this.props.match.params.id}
                    association={this.state.association}
                    canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
                  />} />}
								  {(process.env.REACT_APP_TYPE !== "hv" || this.props.user_type === "super-admin")&&<Route path={`${this.props.match.url}/golfer-products`} render={() =>
										<GolferProducts associationId={this.props.match.params.id}
																association={this.state.association}
																canEditAssociation={getPermission(this.props.user_type, 'edit-association')}
																pushNotificationUser={this.props.push_notification_user}
										/>}
									/>}
									{process.env.REACT_APP_TYPE !== "hv" && this.props.user_type === "super-admin" && <Route path={`${this.props.match.path}/audit-log`} render={() => <AuditLog {...this.props}/>}/>}
                                </Switch>
                            </section>

                        </div>
                    </main>

				<Footer />
            </Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		user_type: state.user.access.user_type,
		push_notification_user: state.user.access.push_notification_user,
		rawAccess: state.user.rawAccess,
		associationName: state.association.profile ? state.association.profile.name : ""
	};
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({updateAssociation}, dispatch);
	return {
		dispatch,
		...actions
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Association)

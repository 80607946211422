import React, {Component} from 'react';
import ClubService from "../../services/api/club";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {
	required,
	isEmailAddress,
	maxLength,
	isPhoneNumber
} from "../../inputs/validations";
import {SelectInput} from "../../inputs/Select";
import {InputField} from "../../inputs/InputField";
import SharedService from "../../services/api/shared";
import {AddClubObservable} from "../../services/observables/AddClubObservable";
import golfClubCategories from "../shared/golfClubCategories";
import technologyProviders from "../shared/technologyProvider";
import {withKeyPress} from "../shared/highOrderComponent/withKeyPress";
import {addAlert} from '../shared/actions';


const golfClubTypes = [
    { value: 'Type 1', label: 'Type 1' },
    { value: 'Type 2', label: 'Type 2' },
    { value: 'Type 3', label: 'Type 3' }
];


class AddNewClubForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            associations: [],
            membershipCodes: [{label: 'J', value: 'J'}, {label: 'R', value: 'R'}],
            startDate: new Date(),
            countries: [],
            states: [],
            ...this.props.initialValues
        };

        this.onSubmit = this.onSubmit.bind(this);
        //his.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        ClubService.getGolfAssociations()
            .then(response => {
            	let selectedAssoc = null;
                let filteredAssociations = response.associations.filter(assoc => !assoc.is_trial);
                let associations = filteredAssociations.map(assoc => {
                	assoc.value = assoc.id; assoc.label = assoc.name;
                	if (parseInt(this.props.association) === assoc.id) {
                		selectedAssoc = assoc;
                	}
                	return assoc
                });
				associations.sort((a, b) => a['label'].toString().localeCompare(b['label'].toString()));
				this.setState({
                    associations
                }, () => {
                	if (selectedAssoc) {
						this.props.change('club.association', selectedAssoc);
					}
				})
            })

        SharedService.getCountriesAndStates()
            .then((response) => {
                this.setState({
                    countries: response.countries.map((item) => {
                        return {
                            ...item,
                            value: item.name,
                            label: item.name,
                            states: item.states.map((state) => {
                                return {
                                    ...state,
                                    label: state.name.includes('Armed ') ? state.code : state.name
                                }
                            })
                        }
                    })
                })
            })
		this.props.onRef(this);
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

    onCountryChange(country) {
        this.setState({
            states: country.states
        })
    }
    onSubmit(values) {
    	let data = JSON.parse(JSON.stringify(values));
    	let association_id = data.club.association.id;
    	data.club.club_category = data.club.club_category.value;
    	data.club.club_type = data.club.club_type.value;
    	data.club_address.country = data.club_address.country.code;
    	data.club_address.state = data.club_address.state.code;
    	data.club.is_test = data.club.is_test ? 'true' : 'false';
    	data.club.technology_provider = data.club.technology_provider.value;
       	data.club.is_dac = data.club.is_dac ? 'true' : 'false';
       	delete data.club.association;

        return ClubService.createClub(association_id, data).then((res) => {
            AddClubObservable.update(association_id);
			this.props.addAlert({type: 'success', message: 'Club has been successfully created!'});
			this.props.onSuccess(res);
        });

    }

	render() {
        const {handleSubmit, submitting} = this.props;
        return (
            <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit)}>

                <div className="row">
                    <div className="col">
                        <label htmlFor="club.association">Association <span>*</span></label>
                        <Field name={'club.association'} disabled={this.props.access.user_type === "association-admin"} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.associations}/>
                    </div>
                </div>


                <div className="row">
                    <div className="col is-full">
                        <label htmlFor="club.club_name">Club Name <span>*</span></label>
                        <Field component={InputField} validate={[required , maxLength([60, 'The Club Name cannot exceed 60 characters.'])]} type="text" name="club.club_name" id="club.club_name" />
                    </div>
                </div>


				{this.props.userType === "super-admin" && <div className="row">
                    <div className="col is-1-of-2">
                        <span className="label">Is DAC ? <span>*</span></span>
                        <div className="radio__container">


							<Field
								onClick={
									() => {
										this.setState((prevState) => {
											return {is_dac: !prevState.is_dac};
										});
									}
								}
								checked={this.state.is_dac}

								id="dac-yes"
								value={true}
								name={'club.is_dac'}
								component={'input'}
								className="simple__radio"
								type="radio"
							/>
							<label htmlFor="dac-yes">Yes</label>


                            <Field
                                onClick={
                                    () => {
                                        this.setState((prevState) => {
                                            return {is_dac: !prevState.is_dac};
                                        });
                                    }
                                }
                                checked={!this.state.is_dac}
                                id="dac-no"
                                value={false}
                                name={'club.is_dac'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                            />
                            <label htmlFor="dac-no">No</label>

                        </div>
                    </div>

                </div>}

                <div className="row">
                    <div className="col is-1-of-4">
                        <label >Club Type <span>*</span></label>
                        <Field name={'club.club_type'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={golfClubTypes}/>
                    </div>
					<div className="col is-1-of-4">
						<label>Club Category <span>*</span></label>
						<Field name={'club.club_category'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={golfClubCategories}/>
					</div>
					<div className="col is-1-of-2">
						<label>Front End Provider <span>*</span></label>
						<Field name={'club.technology_provider'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={technologyProviders}/>
					</div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label>Country <span>*</span></label>
                        <Field onChange={this.onCountryChange.bind(this)} name={'club_address.country'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.countries}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor="club_address.street_1">Address 1 <span>*</span></label>
                        <Field component={InputField} type="text" name="club_address.street_1" id="club_address.street_1" validate={[required, maxLength([46, "Value too long (max 46 characters)"])]}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-full">
                        <label htmlFor="club_address.street_2">Address 2</label>
                        <Field component={InputField} type="text" name="club_address.street_2" id="club_address.street_2" validate={[maxLength([46, "Value too long (max 46 characters)"])]}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col is-3-of-7">
                        <label htmlFor="club_address.city">City <span>*</span></label>
                        <Field component={InputField} validate={[required]} type="text" name="club_address.city" id="club_address.city" />

                    </div>
                    <div className="col is-2-of-7">
                        <label htmlFor="club_address.state">State <span>*</span></label>
                        <Field name={'club_address.state'} validate={[required]} component={SelectInput} className="react-select-container" classNamePrefix="react-select" options={this.state.states}/>
                    </div>
                    <div className="col is-2-of-7">
                        <label htmlFor="club_address.zip">Postal Code <span>*</span></label>
                        <Field component={InputField} validate={[required]} type="text" name="club_address.zip" id="club_address.zip" />
                    </div>
                </div>

                <div className="row">
                    <div className="col is-3-of-7">
                        <label htmlFor="club.phone">Phone <span>*</span></label>
                        <Field component={InputField} validate={[required, isPhoneNumber]} type="text" name="club.phone" id="club.phone" />

                    </div>
                    <div className="col is-4-of-7">
                        <label htmlFor="club.email">Email <span>*</span></label>
                        <Field component={InputField} validate={[required, isEmailAddress]} type="text" name="club.email" id="club.email" />
                    </div>
                </div>

				{this.props.userType === "super-admin" && <div className="row">
                    <div className="col">
                        <label>Test ? <span>*</span></label>
                        <div className="radio__container">

                            <Field
                                onClick={
                                    () => {
                                        this.setState((prevState) => {
                                            return {is_test: !prevState.is_test};
                                        });
                                    }
                                }
                                checked={this.state.is_test}

                                id="test-yes"
                                value={true}
                                name={'club.is_test'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                            />
                            <label htmlFor="test-yes">Yes</label>

                            <Field
                                onClick={
                                    () => {
                                        this.setState((prevState) => {
                                            return {is_test: !prevState.is_test};
                                        });
                                    }
                                }
                                checked={!this.state.is_test}
                                id="test-no"
                                value={false}
                                name={'club.is_test'}
                                component={'input'}
                                className="simple__radio"
                                type="radio"
                            />
                            <label htmlFor="test-no">No</label>
                        </div>
                    </div>
                </div>}

                <div className="row">
                    <div className="col is-1-of-2">
                        <button type={'button'} className="btn fill gray" onClick={this.props.closeModal}>Cancel</button>
                    </div>
                    <div className="col is-1-of-2">
                        <button className="btn fill blue" disabled={submitting} type={'submit'} ref={'submit'}>Save</button>
                    </div>
                </div>

            </form>
		);
	}
}

AddNewClubForm = reduxForm({
    form: 'addNewClubForm',
    destroyOnUnmount: true,
    enableReinitialize: true
})(AddNewClubForm);

function mapStateToProps(state) {
    return {
        enableReinitialize: true,
        keepDirtyOnReinitialize: true,
        initialValues: {
        	club: {
					is_dac: false,
					is_test: false,
				}
        },
		access: state.user.access,
		userType: state.user.access.user_type
	}
}

function mapDispatchToProps(dispatch) {
	let actions = bindActionCreators({addAlert}, dispatch);
    return {
        dispatch,
        ...actions
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withKeyPress(AddNewClubForm));
